import {
  makeStyles,
  createStyles,
  createTheme,
} from '@material-ui/core/styles';

const drawerWidth = 280;

const useStyles = makeStyles(theme =>
  createStyles({
    appBar: {
      transition: theme.transitions.create(['margin', 'width'], {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.leavingScreen,
      }),
      position: 'fixed',
      zIndex: 999,
    },
    appBarShift: {
      width: `calc(102% - ${drawerWidth}px)`,
      transition: theme.transitions.create(['margin', 'width'], {
        easing: theme.transitions.easing.easeOut,
        duration: theme.transitions.duration.enteringScreen,
      }),
    },
    menuButton: {
      marginRight: 10,
      color: '#000000',
      opacity: 0.6,
    },
    displayDiv: {
      display: 'none',
    },
    hide: {
      display: 'none',
    },
    toolbar: {
      backgroundColor: '#ffffff',
      height: 64,
      paddingRight: 0,
      boxShadow: '0px 2px 4px -1px rgba(0,0,0,0.02)',
    },
    toolbarMobile: {
      backgroundColor: '#ffffff',
      boxShadow: '0px 2px 4px -1px rgba(0,0,0,0.02)',
      height: 58,

      width: '100vw',
      position: 'fixed',
      right: 0,
      left: 0,
    },
    button: {
      margin: theme.spacing(1),
      backgroundColor: '#F1F1F1',
      borderRadius: 50,
    },
    buttonBalances: {
      margin: theme.spacing(1),
      backgroundColor: '#ededed',
      borderWidth: 0,
      borderRadius: 50,
      height: '40px',
      width: '180px',
      marginRight: '20px',
    },
    buttonOpinion: {
      background: '#E64300',
      borderRadius: '30px',
      color: '#ffffff',
      textTransform: 'none',
      padding: '7px 20px',
      transition: '.3s',
      '&:hover': {
        background: '#E64300',
      },
    },
    buttonIcon: {
      marginRight: 8,
    },
    buttonIconText: {
      fontFamily: 'DM Sans',
      fontSize: 14,
    },
    buttonName: {
      textTransform: 'none',
    },
    notificationsButton: {
      margin: theme.spacing(1),
    },
    menu: { marginTop: '48px' },
    balanceStyle: { fontSize: 13 },
    balanceIconStyle: { color: '#6f6d70', marginRight: 1 },

    userPrimaryTextStyle: {
      color: '#1B181C',
      opacity: 0.6,
      fontSize: 10,
      marginTop: 15,
      marginBottom: '-8px',
      marginLeft: '6px',
      letterSpacing: '1.5px',
      textTransform: 'uppercase',
      fontFamily: 'DM Sans',
    },
    userMobilePrimaryTextStyle: {
      display: 'none',
      color: '#1B181C',
      fontSize: '1.125rem',
      fontWeight: 500,
      lineHeight: '21px',
      fontFeatureSettings: 'pnum on',
      mixBlendMode: 'normal',
    },

    userMobileSecondaryTextStyle: {
      color: '#1B181C',
      fontSize: 15,
      fontWeight: 500,
      fontFamily: 'DM Sans',
    },
    mobileIconStyle: { padding: 0 },
    userSecondaryTextStyle: {
      color: '#1B181C',
      fontSize: 20,
      fontFamily: 'DM Sans',
      fontWeight: 500,
      '&:hover': {
        cursor: 'pointer',
      },
    },

    areaListButtonList: {
      padding: 5,
      display: 'flex',
      alignContent: 'center',
    },
    iconStyle: {
      marginTop: -6,
      '&:hover': {
        cursor: 'pointer',
        backgroundColor: 'transparent',
      },
    },
    notificationAreaStyle: { justifyContent: 'flex-end', marginLeft: '5px' },
    balanceAreaStyle: { flex: 2 },
    userAreaStyle: {
      flex: 12,
    },
    circularLoading: {
      width: '15px !important',
      height: '15px !important',
      color: '#FFF',
    },
    logoutButton: {
      color: '#999999',
      textTransform: 'initial',
      borderRadius: '51px',
      marginLeft: '10px',
      marginRight: '15px',
      padding: '4px 22px',
      height: '35px',
    },
    logoutButtonIcon: {
      marginRight: '6px',
      width: '22px',
    },
    logoutButtonText: {
      fontFamily: 'DM Sans',
      fontSize: 14,
    },

    hiddenUploadButton: {
      opacity: 0,
      pointerEvents: 'none',
    },

    uploadButton: {
      marginLeft: '6px',
      marginRight: '-20px',
      opacity: 1,
      pointerEvents: 'initial',
    },

    uploadButtonCount: {
      backgroundColor: '#E54217',
      borderRadius: '50%',
      width: 20,
      height: 20,
      color: '#ffffff',
      fontFamily: 'Sofia-Pro',
      fontSize: 12,
      position: 'relative',
      right: '3px',
      bottom: '10px',
      textAlign: 'center',
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
    },
    uploadButtonSuccess: {
      backgroundColor: '#168243',
      borderRadius: '50%',
      width: 20,
      height: 20,
      color: '#ffffff',
      fontFamily: 'Sofia-Pro',
      fontSize: 12,
      position: 'relative',
      right: '3px',
      bottom: '10px',
      textAlign: 'center',
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
    },
    uploadButtonError: {
      backgroundColor: '#B00020',
      borderRadius: '50%',
      width: 20,
      height: 20,
      color: '#ffffff',
      fontFamily: 'Sofia-Pro',
      fontSize: 12,
      position: 'relative',
      right: '3px',
      bottom: '10px',
      textAlign: 'center',
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
    },
    checkIcon: {
      width: 12,
      height: 12,
    },
    menuNotification: {
      width: 340,
      padding: 20,
      display: 'flex',
      justifyContent: 'space-between',
      alignItems: 'center',
    },
    menuNotificationTitle: {
      fontFamily: 'Sofia-Pro',
      fontSize: 14,
    },
    linearProgress: {
      width: '100%',
      minWidth: '270px',
    },
    statusDescriptionError: {
      color: '#E54217',
      fontSize: '13px',
      marginTop: '2px',
    },
    statusDescriptionSuccess: {
      color: '#19797F',
      fontSize: '13px',
      marginTop: '2px',
    },
    statusDescriptionDefault: {
      color: '#000000',
      fontSize: '13px',
      marginTop: '2px',
    },
    cnabStatusContainer: {
      marginTop: theme.spacing(1),
      display: 'flex',
      flexFlow: 'row wrap',
      justifyContent: 'center',
      gap: theme.spacing(1),
      alignItems: 'center',

      '& > div': {
        textWrap: 'pretty',
      },
    },

    retryButton: {
      color: '#52197f',

      '& svg': {
        color: 'inherit',
      },
    },
  }),
);

export default useStyles;

export const theme = createTheme({
  overrides: {
    MuiPaper: {
      elevation4: {
        boxShadow: '0px 2px 4px -1px rgba(0,0,0,0.14) !important',
      },
    },
    MuiOutlinedInput: {
      root: {
        '& $notchedOutline': {
          color: '#52197f',
        },
        '&$focused $notchedOutline': {
          borderColor: '#52197f',
        },
      },
    },
  },
});
