import React from 'react';

import { Table } from '../../../../../../components/Table';
import { Filter } from '../../../../../../components/Filter';
import Form from '../../../../../../components/Form';

import { useReprovedEmployees } from './Hooks/useReprovedEmployees';
import { useTableConfig } from './Hooks/useTableConfig';
import useStyles from './styles';

export function ReprovedEmployeesTab() {
  const styles = useStyles();

  const {
    meta: { bankDataOptions, statusOptions },
    filter,
    table,
  } = useReprovedEmployees();

  const tableConfig = useTableConfig();

  return (
    <>
      <Filter {...filter}>
        <Form.MaskedField
          name="nameOrCpf"
          label="Pesquisar por nome ou CPF"
          mask={[
            {
              mask: '000.000.000-00',
            },
            {
              mask: /\D/g,
            },
          ]}
        />
        <Form.TextField name="registration" label="Matrícula" />
        <Form.SelectField
          name="hasBankData"
          label="Dados bancários"
          options={bankDataOptions}
        />
        <Form.SelectField
          name="status"
          label="Situação"
          options={statusOptions}
        />
      </Filter>
      <div className={styles.rootTable}>
        <Table
          identifier="id"
          hasPagination
          tableConfig={tableConfig}
          {...table}
        />
      </div>
    </>
  );
}
