import { reduxApi } from '../../../../services/reduxApi';

const cancelledEmployeesTags = {
  tag: 'CANCELLED_EMPLOYEES',
  listAbstractTag: { type: 'CANCELLED_EMPLOYEES', id: 'LIST' },
};

const slice = reduxApi
  .enhanceEndpoints({
    addTagTypes: [cancelledEmployeesTags.tag],
  })
  .injectEndpoints({
    endpoints: builder => ({
      searchCancelledEmployees: builder.query({
        query: ({ params = {} ,filter = {}, companyCode = '' }) => {
          return {
            url: `employees/search/canceled/${companyCode}`,
            method: 'POST',
            params,
            body: filter,
          };
        },
        providesTags: [cancelledEmployeesTags.listAbstractTag],
      }),
      cancelEmployer: builder.mutation({
        query: ({ companyCode = '', cpf = '', isReproved = false }) => ({
          url: `/employees/cancel/${cpf}/${companyCode}`,
          method: 'POST',
          params: { isReproved },
        }),
        invalidatesTags: [cancelledEmployeesTags.listAbstractTag],
      }),
    }),
  });

export const {
  useSearchCancelledEmployeesQuery,
  useCancelEmployerMutation,
} = slice;
