export function useCardList({ config = [], data = [] }) {
  function handleCardHeader(row, firstField, cardSelect) {
    const select = cardSelect;
    const cardTitle = firstField?.label;
    const cardDescription = firstField?.render
      ? firstField?.render(row)
      : row[firstField?.key];

    return {
      select,
      title: cardTitle,
      description: cardDescription,
    };
  }

  function handleCardBody(row, otherFields) {
    const otherInfos = otherFields?.map(field => ({
      title: field?.label,
      description: field?.render ? field?.render(row) : row[field?.key],
    }));

    const actions = config
      ?.filter(item => item?.type === 'action')
      ?.map(action =>
        action?.render ? action?.render(row, true) : action.icon,
      );

    return {
      otherInfos,
      actions,
    };
  }

  function handleOrganizeCard(row, index) {
    const fields = config?.filter(item => item?.type === 'field');
    const select = config?.filter(item => item?.type === 'select')?.[0];

    const otherFields = fields?.filter(
      field => field?.key !== fields?.[0]?.key,
    );

    return {
      cardId: `card_${index}`,
      cardHeader: handleCardHeader(
        row,
        fields?.[0],
        select?.render(row) ?? null,
      ),
      cardBody: handleCardBody(row, otherFields),
    };
  }

  function handleOrganizeListData(dataParam) {
    return {
      mainSelect:
        config?.filter(item => item?.type === 'select')?.[0]?.label(true) ??
        null,
      cards: dataParam?.map((row, index) => handleOrganizeCard(row, index)),
    };
  }

  return handleOrganizeListData(data);
}
