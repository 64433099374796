import React, { createContext, useContext, useEffect, useState } from 'react';
import NiceModal from '@ebay/nice-modal-react';
import { useSelector } from 'react-redux';
import { useDispatch } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { pusher } from '../../../../utils/pusher';
import { pusherStatus, pusherEvents } from '../../../../enums/pusher';


import { useLazyGetEmployeesListQuantityQuery } from '../../../../redux/store/Employees/services/employees';
import { useCreateOnboardingReportMutation, OnboardingActions } from '../../../../redux/store/Employees/Onboard';
import { handleSnackbarStack } from '../../../../utils/handleSnackbarStack';

const EmployeesContext = createContext();

export function EmployeesProvider({ children }) {
  const dispatch = useDispatch()
  const [ createOnboardingReport, { isLoading:isLoadingCreateOnboardingReport } ] = useCreateOnboardingReportMutation()
  const history = useHistory(); 
  const companyCode = useSelector(state => state.companies.currentCompany.code);

  const [currentTab, setCurrentTab] = useState('approved');
  const [totalApprovedEmployees, setTotalApprovedEmployees] = useState(0);
  const [totalPendingEmployees, setTotalPendingEmployees] = useState(0);
  const [totalReprovedEmployees, setTotalReprovedEmployees] = useState(0);
  const [totalCanceledEmployee, setTotalCanceledEmployee] = useState(0);

  const [
    handleGetEmployeesListQuantity,
    { isLoading },
  ] = useLazyGetEmployeesListQuantityQuery();

  const uploadDataEmployees = JSON.parse(
    localStorage.getItem(`uploadDataEmployees-${companyCode}`),
  );

  const handleCreateOnboardReport = ()=>{
    createOnboardingReport({companyCode})
    
    const channel = pusher.subscribe(companyCode);

    channel.bind(pusherEvents.PROCESS_ONBOARDING_REPORT, response => {
      dispatch(OnboardingActions.setResponse(response));
    });
  }

  function handleEmployeesRegisterBatchOptionClick() {
    if (
      uploadDataEmployees &&
      uploadDataEmployees?.status === 'PROCESSING' &&
      uploadDataEmployees?.companyCode === companyCode
    ) {
      NiceModal.show('already-upload-register-file');
    } else {
      history.push('/employees/register-batch/menu');
    }
  };

  const updateCounts = () => {
    handleGetEmployeesListQuantity(companyCode)
      .then(({ data }) => {
        setTotalApprovedEmployees(data?.approvedEmployee);
        setTotalPendingEmployees(data?.pendingEmployee);
        setTotalReprovedEmployees(data?.reprovedEmployee);
        setTotalCanceledEmployee(data?.canceledEmployee);
      })
      .catch(() => {
        handleSnackbarStack().error(
          'Houve um erro ao buscar a quantidade de funcionários',
        );
      });
  };

  useEffect(() => {
    updateCounts()

    return () => {
      setTotalApprovedEmployees(0);
      setTotalPendingEmployees(0);
      setTotalReprovedEmployees(0);
      setTotalCanceledEmployee(0);
    };
  }, []);

  const value = {
    history,
    handleCreateOnboardReport,
    isLoadingCreateOnboardingReport,
    currentTab,
    totalApprovedEmployees: isLoading ? '-' : totalApprovedEmployees,
    totalPendingEmployees: isLoading ? '-' : totalPendingEmployees,
    totalReprovedEmployees: isLoading ? '-' : totalReprovedEmployees,
    totalCanceledEmployee: isLoading ? '-' : totalCanceledEmployee,
    handleEmployeesRegisterBatchOptionClick,
    updateCounts,
    handleChangeTab: (_, tab) => setCurrentTab(tab),
  };

  return (
    <EmployeesContext.Provider value={value}>
      {children}
    </EmployeesContext.Provider>
  );
}

export const useEmployeesContext = () => {
  const context = useContext(EmployeesContext);
  if (!context) {
    throw new Error('useEmployeesContext deve ser usado dentro de EmployeesProvider');
  }
  return context;
};
