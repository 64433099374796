import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import { useHistory, Prompt } from 'react-router-dom';
import moment from 'moment';

import {
  Button,
  CircularProgress,
  IconButton,
  Hidden,
  Typography,
  TextField,
} from '@material-ui/core';

import WarningRoundedIcon from '@material-ui/icons/WarningRounded';
import CloseRoundedIcon from '@material-ui/icons/CloseRounded';

import ProgressToolBar from '../../../components/ProgressToolBar';

import styles from './styles.module.css';
import { api } from '../../../services/api';
import capitalize from '../../../utils/textCapitalize';
import { isMobile } from '../../../utils/breakpoints';
import brazilianCurrencyFormatter2 from '../../../utils/brazilianCurrencyFormatter2';
import formatOnlyNumbers from '../../../utils/formatOnlyNumbers';

const NewBillingDeposit = () => {
  const [billValue, setBillValue] = useState(0);
  const [notes, setNotes] = useState('');
  const [error, setError] = useState('');
  const [loading, setLoading] = useState(false);
  const [isBlocking, setIsBlocking] = useState(true);
  const [textFieldFocused, setTextFieldFocused] = useState('');

  const { accountSelected } = useSelector(state => state.account);

  const history = useHistory();

  const companies = JSON.parse(sessionStorage.getItem('companies'));
  const currentCompanyIndex = JSON.parse(
    sessionStorage.getItem('currentCompanyIndex'),
  );
  const companyCodeValueStorage = sessionStorage.getItem('currentCompanyCode');
  const company = companyCodeValueStorage || companies[0].code;

  const companyName = companies[currentCompanyIndex]?.name
    ? companies[currentCompanyIndex].name
    : companies[0].name;

  const onBackClicked = () => {
    history.push(`/cash-in`);
  };

  const parseString = value => {
    let s = String(value).replace(',', '.');
    return parseFloat(s / 100);
  };
  const onNextClicked = () => {
    setIsBlocking(false);
    setLoading(true);

    const requestOptions = {
      headers: {
        depositAccountId: accountSelected?.encodedKey,
        accountOrigin: accountSelected?.origin,
      },
      params: {
        amount: parseString(billValue),
        notes,
      },
    };

    api
      .post(`/cashIn/bankTickets/${company}`, {}, requestOptions)
      .then(({ data }) => {
        sessionStorage.setItem('billetData', JSON.stringify(data));
        sessionStorage.setItem('billetValue', JSON.stringify(billValue));

        sessionStorage.setItem(
          'useTopBar',
          JSON.stringify({
            name: 'Boleto pronto para pagamento!',
            step: 'lastStep',
            route: '/cash-in',
          }),
        );

        history.push('/cash-in/new/billing/genereted-billet');
      })
      .catch(
        ({
          response: {
            data: { errors },
          },
        }) => {
          return setError(errors[0].errorDetail);
        },
      )
      .finally(() => setLoading(false));
  };

  const errorCrurrencyValue = currency => {
    if (currency < parseFloat(20.0 * 100) && currency !== 0) {
      return (
        <Typography className={styles.currencyError}>
          Digite um valor à partir de R$ 20,00
        </Typography>
      );
    }
    if (currency > parseFloat(9.0 * 100000000.0)) {
      return (
        <Typography className={styles.currencyError}>
          Digite um valor no máximo até R$ 9.000.000,00
        </Typography>
      );
    }
    return (
      <Typography className={styles.helperText}>
        {!isMobile && `*Obrigatório`}
      </Typography>
    );
  };

  return (
    <div>
      <Prompt
        when={isBlocking}
        message="Você tem certeza que deseja cancelar a criação deste depósito via boleto?"
      />
      <Hidden xsDown>
        <Typography className={styles.titleStyle}>
          Novo depósito via boleto
        </Typography>
        {error && (
          <Typography className={styles.titleStyleError}>{error}</Typography>
        )}
      </Hidden>

      <div className={styles.contentStyle}>
        <Hidden xsDown>
          <Typography className={styles.biggerTextTitleStyle}>
            Informações do Boleto
          </Typography>
        </Hidden>

        <div className={styles.detailsContainer}>
          <Hidden xsDown>
            <div className={styles.details}>
              <Typography className={styles.detailsLabel}>
                Grupo Empresarial
              </Typography>
              <Typography className={styles.detailsText}>
                {capitalize(companyName)}
              </Typography>
            </div>

            <div className={styles.details}>
              <Typography className={styles.detailsLabel}>
                Vencimento
              </Typography>
              <Typography className={styles.detailsText}>
                {moment()
                  .add(7, 'day')
                  .format('DD/MM/YYYY')}
              </Typography>
            </div>

            <div className={styles.details}>
              <Typography className={styles.detailsLabel}>
                Tempo de compensação
              </Typography>
              <Typography className={styles.detailsText}>
                Até 3 dias úteis
              </Typography>
            </div>
          </Hidden>
        </div>

        <div>
          <Hidden xsDown>
            <Typography className={styles.biggerTextTitleStyle}>
              Insira as informações abaixo para gerar o seu boleto
            </Typography>
            <Typography className={styles.biggerTextStyle}>
              Você pode criar um boleto com valor entre
              <span className={styles.purpleLabel}> R$20,00</span> e
              <span className={styles.purpleLabel}> R$9.000.000,00</span>.
            </Typography>
          </Hidden>

          <div className={styles.inputsContainer}>
            <TextField
              variant="outlined"
              placeholder="Valor do boleto*"
              value={brazilianCurrencyFormatter2(billValue)}
              onChange={event =>
                setBillValue(formatOnlyNumbers(event.target.value))
              }
              inputProps={{
                maxLength: 15,
                minLenght: 8,
              }}
              disabled={loading}
              className={styles.valueBilletInput}
              label="Valor do boleto*"
              helperText={
                isMobile && (
                  <span>
                    Digite um valor entre{' '}
                    <span className={styles.purpleLabelHelper}>R$ 20,00</span> e{' '}
                    <span className={styles.purpleLabelHelper}>
                      R$ 9.000.000,00
                    </span>
                    .
                  </span>
                )
              }
            />
            <TextField
              variant="outlined"
              placeholder="Observações"
              onChange={e => setNotes(e.target.value)}
              disabled={loading}
              className={styles.observeInput}
              label="Observações"
              value={notes}
              rows={isMobile && 3}
              onFocus={() =>
                setTimeout(() => setTextFieldFocused('notes'), 200)
              }
              onBlur={() => setTimeout(() => setTextFieldFocused(''), 200)}
              InputProps={{
                endAdornment: notes?.length > 0 &&
                  textFieldFocused === 'notes' && (
                    <IconButton
                      className={styles.endAdornment}
                      onClick={() => setNotes('')}
                    >
                      <CloseRoundedIcon className={styles.endAdornmentIcon} />
                    </IconButton>
                  ),
              }}
            />
          </div>
          {errorCrurrencyValue(billValue)}
        </div>
      </div>

      <Hidden smUp>
        <div
          className={styles.contentStyleResponsive}
          style={{ display: 'flex' }}
        >
          <div className={styles.details}>
            <Typography className={styles.detailsLabel}>Vencimento</Typography>
            <Typography className={styles.detailsText}>
              {moment()
                .add(7, 'day')
                .format('DD/MM/YYYY')}
            </Typography>
          </div>

          <div className={styles.details}>
            <Typography className={styles.detailsLabel}>
              Tempo de compensação
            </Typography>
            <Typography className={styles.detailsText}>
              Até 3 dias úteis
            </Typography>
          </div>
        </div>
      </Hidden>

      <Hidden smUp>
        <>
          {error && (
            <div className={styles.warning}>
              <WarningRoundedIcon />
              <span>
                Não foi possível gerar o boleto, favor tente novamente mais
                tarde.
              </span>
            </div>
          )}
          <Button
            className={styles.confirmButton}
            onClick={onNextClicked}
            disabled={!(billValue > 1999)}
          >
            {loading ? (
              <CircularProgress className={styles.circularProgress} />
            ) : (
              'Emitir boleto'
            )}
          </Button>
        </>
      </Hidden>

      <Hidden xsDown>
        <ProgressToolBar
          disabled={!(billValue > 1999)}
          loading={loading}
          numberOfSteps={0}
          currentStep={0}
          onBackClicked={onBackClicked}
          onNextClicked={onNextClicked}
          nextButtonText="Emitir Boleto"
        />
      </Hidden>
    </div>
  );
};

export default NewBillingDeposit;
