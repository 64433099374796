import { makeStyles } from "@material-ui/styles";
import { Theme } from "@material-ui/core/styles";

const useStyles = makeStyles((theme:Theme) => ({
    rootTable: {
      "& tr":{
        height: 68
      },
      "& th:last-child": {
        '& div':{
          display: 'flex',
          justifyContent: 'center',
        }
      },
      "& td:last-child": {
        textAlign: "center",
        "& div":{
          display: 'flex',
          justifyContent: 'center',
        }
      },
    },
}));

export default useStyles;