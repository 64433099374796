import React from 'react';
import NiceModal from '@ebay/nice-modal-react';

import { Box, Button, Typography, Tooltip } from '@material-ui/core';
import { EditOutlined, FiberManualRecord } from '@material-ui/icons';

import formatCPF from '../../../../../../../utils/formatCPF';
import { useGetBankListQuery } from '../../../../../../../redux/store/Employees/services/employees';
import { useCancelEmployerMutation } from '../../../../../../../redux/store/Employees/services/cancelledEmployees.api';

import CancelOutlinedIcon from '@material-ui/icons/CancelOutlined';

import EmployeesCancelModal from '../../../EmployeesCancelModal'
import { usePendingEmployees } from './usePendingEmployees'
import { useEmployeesContext } from '../../../../hooks/useEmployeesList';


export function useTableConfig() {
    const { updateCounts } = useEmployeesContext();
  
  const { data: bankList } = useGetBankListQuery();
  const { table } = usePendingEmployees()
  
  const handleOpenCancelModal = (row) => {
    NiceModal.show(EmployeesCancelModal, {
      employee: row,
      onSuccess: ()=>{ 
        table.refetch() 
        updateCounts()
      }
    })
  }
  const [ cancelEmployer ] = useCancelEmployerMutation();

  const showEmployeeBankDataModal = employee => {
    NiceModal.show('handle-employee-bank-data', {
      employee: {
        ...employee,
        bank: employee?.bank || '',
        accountType: employee?.accountType ?? '',
        agency: employee?.agency ?? '',
        agencyDigit: employee?.agencyDigit ?? '',
        account: employee?.account ?? '',
        accountDigit: employee?.accountDigit ?? '',
      },
      bankList,
      employeeType: 'PENDING',
    });
  };

  const handleCancel = (row) => {
    cancelEmployer({
      companyCode: row.companyEncodedKey,
      cpf: row.cpf
    })
  }

  return [
    {
      key: 'registration',
      type: 'field',
      label: 'Matrícula',
    },
    {
      key: 'cpf',
      type: 'field',
      label: 'CPF',
      render: row => (row?.cpf ? formatCPF(row?.cpf) : '-'),
    },
    {
      key: 'name',
      type: 'field',
      label: 'Nome',
      render: row => row?.name ?? '-',
    },
    {
      key: 'hasBankData',
      type: 'field',
      label: 'Dados bancários',
      render: ({ hasBankData }) => (
        <Box
          sx={{
            color: hasBankData ? '#19797F' : '#E54217',
            display: 'flex',
            flexFlow: 'row nowrap',
            alignItems: 'center',
            gap: '0.2rem',
          }}
        >
          <FiberManualRecord style={{ fontSize: '0.6rem' }} />
          <Typography style={{ fontSize: '14px', fontWeight: 'bold' }}>
            {hasBankData ? 'Sim' : 'Não'}
          </Typography>
        </Box>
      ),
    },
    {
      key: 'employeeDetails',
      type: 'field',
      label: 'Ações',
      icon: <EditOutlined color="primary" />,
      render: (row) =>{
        return (
          <>
            <Tooltip title="Visualizar e editar dados bancários">
              <Button onClick={() => showEmployeeBankDataModal(row)}>
                <EditOutlined color="primary" />
              </Button>
            </Tooltip>
            <Tooltip title="Cancelar Cadastro">
              <Button onClick={() => handleOpenCancelModal(row)}>
                <CancelOutlinedIcon color="error" />
              </Button>
            </Tooltip>
          </>
        )
      }
    }
  ];
}
