import { createSlice } from '@reduxjs/toolkit';

const onboardingSlice = createSlice({
  name: 'onboarding',
  initialState: {
    onboardingReportData: null,
  },
  reducers: {
    setResponse(state, action) {
      state.onboardingReportData = action.payload;
    },
    clearResponse(state) {
      state.onboardingReportData = null;
    },
  },
});

export const OnboardingActions = onboardingSlice.actions;
export const onboardingReducer = onboardingSlice.reducer;
export default onboardingReducer;