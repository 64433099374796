import { reduxApi } from '../../../../services/reduxApi';

const onboardingTags = {
    tag: 'ONBOARDING',
};

const slice = reduxApi
  .enhanceEndpoints({
    addTagTypes: [onboardingTags.tag],
  })
  .injectEndpoints({
    endpoints: (builder) => ({
      createOnboardingReport: builder.mutation({
        query: ({ companyCode }) => ({
          url: `/onboarding/report/businessGroup/companyCode/${companyCode}`,
          method: 'POST',
        }),

        transformErrorResponse: (response:any) => {
          return response?.data?.errors?.[0];
        },
      }),
    }),
  });

export const {
  useCreateOnboardingReportMutation,
} = slice;
