import React, { useEffect, useState } from 'react';
import { Add } from '@material-ui/icons';
import { Header } from '../../../components/Header';
import Buttons from '../../../components/Buttons';

import { CircularProgress } from '@material-ui/core';

import {ReactComponent as DownloadIcon } from '../../../assets/download.svg'

import { Tabs } from './components/Tabs';
import { useEmployeesList } from './hooks/useEmployeesList';
import { EmployeesProvider, useEmployeesContext } from './hooks/useEmployeesList';
import { useSelector } from 'react-redux'

function List() {
  const {
    history,
    handleEmployeesRegisterBatchOptionClick,
    currentTab,
    handleChangeTab,
    totalApprovedEmployees,
    totalPendingEmployees,
    totalReprovedEmployees,
    handleCreateOnboardReport,
    isLoadingCreateOnboardingReport,
    totalCanceledEmployee
  } = useEmployeesContext();

  const onboardingReportData = useSelector(
    state => state.onboarding.onboardingReportData,
  );

  const isLoadingOnboardReportData = onboardingReportData?.status === 'PROCESSING' || isLoadingCreateOnboardingReport

  return (
    <>
      <Header
        title="Gerenciar funcionários"
        buttons={
          <>
            <Buttons.PrimaryButton
              icon={isLoadingOnboardReportData ? <CircularProgress color='#CBBAD9' style={{width: 17, height: 17}}/> : <DownloadIcon />}
              title="Baixar relatório"
              variant='text'
              disabled={isLoadingOnboardReportData}
              onClick={()=>{
                handleCreateOnboardReport()
              }}
            />
            <Buttons.RoundedButton
              icon={<Add />}
              title="Incluir funcionários"
              options={[
                {
                  title: 'Cadastrar individualmente',
                  onClick: () => history.push('employees/register-single'),
                },
                {
                  title: 'Cadastrar a partir de um arquivo',
                  onClick: handleEmployeesRegisterBatchOptionClick,
                },
              ]}
            />
          </>
        }
      />

      <Tabs
        currentTab={currentTab}
        handleChangeTab={handleChangeTab}
        employeesTabsQuantities={{
          totalApprovedEmployees,
          totalPendingEmployees,
          totalReprovedEmployees,
          totalCanceledEmployee
        }}
      />
    </>
  );
}

export const EmployeesList = ()=>(
  <EmployeesProvider>
    <List/>
  </EmployeesProvider>
)
