import React, { useEffect } from 'react';
import NiceModal, { useModal } from '@ebay/nice-modal-react';
import {
  Dialog,
  DialogActions,
  DialogContent,
  Typography,
  IconButton,
} from '@material-ui/core';
import useStyles from './styles';
import CloseRoundedIcon from '@material-ui/icons/CloseRounded';
import Buttons from '../../../../../components/Buttons';
import { useCancelEmployerMutation } from '../../../../../redux/store/Employees/services/cancelledEmployees.api';
import { handleSnackbarStack } from '../../../../../utils/handleSnackbarStack';
interface QrCodeModalProps {
    employee: any;
    isReproved: boolean;
    onSuccess: ()=> void;
}

const EmployeesCancelModal = NiceModal.create(({ 
    employee,
    onSuccess,
    isReproved
}:QrCodeModalProps) => {

  const [ cancelEmployer, { isLoading, isSuccess, isError } ] = useCancelEmployerMutation();
  const { error } = handleSnackbarStack()

  const handleCancel = () => {
    cancelEmployer({
      companyCode: employee.companyEncodedKey,
      cpf: employee.cpf,
      isReproved: !!isReproved
    })
  }
  
  const modal = useModal();
  const styles = useStyles();

  useEffect(()=>{
    if(isSuccess) { 
        onSuccess()
        return modal.remove()
    }

    if(isError) { 
        error('Não foi possível cancelar o cadastro. Tente novamente mais tarde.')
        return modal.remove()
    }
  },[isSuccess, isError])

  return (
    <Dialog
      fullWidth
      maxWidth="xs"
      open={modal.visible}
      onClose={modal.remove}
      PaperProps={{ className: styles.root }}
    >
      <IconButton onClick={modal.remove} className={styles.closeIcon}>
        <CloseRoundedIcon />
      </IconButton>
      <DialogContent className={styles.header}>
        <Typography variant='h2' className={styles.title}>Deseja continuar?</Typography>
        <Typography variant='h5' className={styles.description}>
            Colaboradores cancelados não poderão realizar o processo de onboarding.
        </Typography>
      </DialogContent>
      <DialogActions className={styles.buttonContainer}>
        <Buttons.PrimaryButton
            color="primary"
            variant="text"
            title="NÃO"
            disabled={isLoading}
            onClick={modal.remove}
        />
        <Buttons.PrimaryButton
            color="secondary"
            title="SIM"
            loading={isLoading}
            disabled={isLoading}
            onClick={()=>{
                handleCancel()
            }}
        />
      </DialogActions>
    </Dialog>
  );
});

export default EmployeesCancelModal;
