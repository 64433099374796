import { combineReducers } from 'redux';
import { persistReducer } from 'redux-persist';
import storageSession from 'redux-persist/lib/storage/session';

import userReducer from './user';
import batchReducer from './batchRegister';
import currentCompanyReducer from './currentCompany';
import excelFileDataReducer from './excelFileData';
import payrollReducer from './payroll';
import headerSuccess from './headerSuccess';
import importFiles from './importFiles';
import employerReducer from './employer';
import billingDeposit from './billingDeposit';
import singleEmployerReducer from './singleEmployer';
import employeeSuccessfully from './employeeSuccessfully';
import manualPayroll from './manualPayroll';
import applyValueInputs from './applyValueInputs';
import insuranceReducer from './insuranceReducer';
import feedbackModal from './feedbackModal';
import showBalances from './showBalances';
import openAndClosedDrawer from './openAndClosedDrawer';
import applyValueTotalAmount from './applyValueTotalAmount';
import situationPointPlan from './situationPointPlan';

import { accountReducer } from '../store/Accounts';
import { balanceReducer } from '../store/Balances';
import { companiesReducer } from '../store/Companies';
import { pointOnlineReducer } from '../store/PointOnline';
import { ImportFilePayrollErrorsReducer } from '../store/Payroll/FilePayrollErrors';
import { employeePaymentsQrCodeInformationReducer } from '../store/EmployeePaymentQrCodeInformation';
import { OnboardingReprovedGeneralReducer } from '../store/Employees/OnboardingReproved';
import { employeesRegisterFilesGeneralReducer } from '../store/Employees/RegisterFiles';
import { EmployeesRegisterFilesDetailsReducer } from '../store/Employees/RegisterFiles/FileDetails';
import { manualPayrollReducer } from '../store/Payroll/ManualPayroll';
import { payrollFileUploadReducer } from '../store/Payroll/PayrollFiles';

import conversation from '../../components/Chat/store/conversation/reducer';
import page from '../../components/Chat/store/page/reducer';
import user from '../../components/Chat/store/user/reducer';
import config from '../../components/Chat/store/config/reducer';
import { employeeDetailsReducer } from '../store/Employees/EmployeeDetails';
import { payrollDetailsReducer } from '../store/Payroll/PayrollDetails';
import { reduxApi } from '../../services/reduxApi';
import { employeesGeneralReducer } from '../store/Employees';
import { cashOutReducer } from '../store/CashOut';
import { academyReducer } from '../store/Academy';
import { resetPasswordReducer } from '../store/ResetPassword';
import { accessReducer } from '../store/AccessSettings';
import { userManagementReducer } from '../store/UserManagement';
import { pixStatusReducer } from '../store/Pix/actions';
import { limitManagementReducer } from '../store/LimitManagement';
import { portabilityStatusReducer } from '../store/Portability/actions';
import { fileDataReducer } from '../store/FileData';
import { onboardingReducer } from '../store/Employees/Onboard';

const payrollDetailsSessionPersistConfig = {
  key: 'payrollDetails',
  storage: storageSession,
};

const manualPayrollSessionPersistConfig = {
  key: 'manualPayroll',
  storage: storageSession,
};

const importFilePayrollErrorsListSessionPersistConfig = {
  key: 'importFilePayrollErrors',
  storage: storageSession,
};

const employeeDetailsSessionPersistConfig = {
  key: 'employeeDetails',
  storage: storageSession,
};

const employeesGeneralSessionPersistConfig = {
  key: 'employeesGeneral',
  storage: storageSession,
};

const employeeRegisterFilesGeneralSessionPersistConfig = {
  key: 'employeeRegisterFilesGeneral',
  storage: storageSession,
};

const employeeRegisterFilesDetailsSessionPersistConfig = {
  key: 'employeeRegisterFilesDetails',
  storage: storageSession,
};

export default combineReducers({
  [reduxApi.reducerPath]: reduxApi.reducer,
  userReducer,
  batchReducer,
  currentCompanyReducer,
  excelFileDataReducer,
  payrollReducer,
  headerSuccess,
  importFiles,
  employerReducer,
  billingDeposit,
  singleEmployerReducer,
  employeeSuccessfully,
  manualPayroll,
  applyValueInputs,
  resetPassword: resetPasswordReducer,
  insuranceReducer,
  page,
  user,
  config,
  conversation,
  feedbackModal,
  showBalances,
  openAndClosedDrawer,
  applyValueTotalAmount,
  situationPointPlan,
  account: accountReducer,
  balance: balanceReducer,
  companies: companiesReducer,
  pointOnline: pointOnlineReducer,
  payroll: combineReducers({
    payrollDetails: persistReducer(
      payrollDetailsSessionPersistConfig,
      payrollDetailsReducer,
    ),
    manualPayroll: persistReducer(
      manualPayrollSessionPersistConfig,
      manualPayrollReducer,
    ),
    importFilePayrollErrors: persistReducer(
      importFilePayrollErrorsListSessionPersistConfig,
      ImportFilePayrollErrorsReducer,
    ),
    payrollFilesUpload: payrollFileUploadReducer,
  }),
  employees: combineReducers({
    employeesGeneral: persistReducer(
      employeesGeneralSessionPersistConfig,
      employeesGeneralReducer,
    ),
    employeeDetails: persistReducer(
      employeeDetailsSessionPersistConfig,
      employeeDetailsReducer,
    ),
    employeesRegisterFiles: combineReducers({
      general: persistReducer(
        employeeRegisterFilesGeneralSessionPersistConfig,
        employeesRegisterFilesGeneralReducer,
      ),
      details: persistReducer(
        employeeRegisterFilesDetailsSessionPersistConfig,
        EmployeesRegisterFilesDetailsReducer,
      ),
    }),

    onboardingReprovedGeneral: OnboardingReprovedGeneralReducer,
  }),
  employeePaymentsQrCodeInformation: employeePaymentsQrCodeInformationReducer,
  cashOut: cashOutReducer,
  academy: academyReducer,
  access: accessReducer,
  userManagement: userManagementReducer,
  pixStatus: pixStatusReducer,
  limitManagement: limitManagementReducer,
  portabilityStatus: portabilityStatusReducer,
  fileData: fileDataReducer,
  onboarding: onboardingReducer,
});
