import { makeStyles } from "@material-ui/styles";
import { Theme } from "@material-ui/core/styles";

const useStyles = makeStyles((theme:Theme) => ({
    tabsContainer: {
        overflow: 'auto'
    },
    indicator: {
        backgroundColor: 'var(--purple)',
    },
    flexContainer: {
        borderBottom: '1px solid #B3B3B3',
        overflowX: 'auto',
        whiteSpace: 'nowrap',
        '&::-webkit-scrollbar': {
            height: '0.5px', 
        },
        '&::-webkit-scrollbar-thumb': {
            backgroundColor: 'var(--purple)',
            borderRadius: '1px', 
        },
        '&::-webkit-scrollbar-track': {
            backgroundColor: 'transparent', 
        },
    },
}));

export default useStyles;