import React from 'react';
import NiceModal from '@ebay/nice-modal-react';

import { Box, Typography, Tooltip, Button } from '@material-ui/core';
import {
  EditOutlined,
  FiberManualRecord,
  InfoOutlined,
} from '@material-ui/icons';

import formatCPF from '../../../../../../../utils/formatCPF';
import { situationOfRejectedEmployees } from '../../../../../../../mocks/situationOfEmployees';
import { useGetBankListQuery } from '../../../../../../../redux/store/Employees/services/employees';
import Buttons from '../../../../../../../components/Buttons';
import EmployeesCancelModal from '../../../EmployeesCancelModal'
import CancelOutlinedIcon from '@material-ui/icons/CancelOutlined';

import { useReprovedEmployees } from './useReprovedEmployees'
import { useEmployeesContext } from '../../../../hooks/useEmployeesList';

export function useTableConfig() {
  const { updateCounts } = useEmployeesContext();

  const { data: bankList } = useGetBankListQuery();
  const { table } = useReprovedEmployees()

  function handleOpenInfoDrawer() {
    NiceModal.show('employee-situation-info-drawer');
  }

  const handleOpenCancelModal = (row) => {
    NiceModal.show(EmployeesCancelModal, {
      employee: row,
      isReproved: true,
      onSuccess: ()=>{ 
        table.refetch() 
        updateCounts()
      }
    })
  }

  function handleShowEmployeeBankDataModal(employee) {
    NiceModal.show('handle-employee-bank-data', {
      employee: {
        ...employee,
        bank: employee?.bank || '',
        accountType: employee?.accountType ?? '',
        agency: employee?.agency ?? '',
        agencyDigit: employee?.agencyDigit ?? '',
        account: employee?.account ?? '',
        accountDigit: employee?.accountDigit ?? '',
      },
      bankList,
      employeeType: 'REPROVED',
    });
  }

  return [
    {
      key: 'registration',
      type: 'field',
      label: 'Matrícula',
    },
    {
      key: 'cpf',
      type: 'field',
      label: 'CPF',
      render: row => (row?.cpf ? formatCPF(row?.cpf) : '-'),
    },
    {
      key: 'name',
      type: 'field',
      label: 'Nome',
      render: row => row?.name ?? '-',
    },
    {
      key: 'hasBankData',
      type: 'field',
      label: 'Dados bancários',
      render: ({ hasBankData }) => (
        <Box
          sx={{
            color: hasBankData ? '#19797F' : '#E54217',
            display: 'flex',
            flexFlow: 'row nowrap',
            alignItems: 'center',
            gap: '0.2rem',
          }}
        >
          <FiberManualRecord style={{ fontSize: '0.6rem' }} />
          <Typography style={{ fontSize: '14px', fontWeight: 'bold' }}>
            {hasBankData ? 'Sim' : 'Não'}
          </Typography>
        </Box>
      ),
    },
    {
      key: 'situationType',
      type: 'field',
      label: 'Situação',
      customLabel: () => (
        <Buttons.IconButton
          icon={<InfoOutlined style={{ fontSize: '1.2rem' }} />}
          color="primary"
          onClick={handleOpenInfoDrawer}
          style={{ width: '1rem', height: '1rem' }}
        />
      ),
      render: ({ situationType }) =>
        situationOfRejectedEmployees.find(item => item.search === situationType)
          .text,
    },
    {
      key: 'employeeDetails',
      type: 'field',
      label: 'Ações',
      icon: <EditOutlined color="primary" />,
      render: (row) =>{
        return (
          <Box display="flex" flexDirection={"row"}>
            <Tooltip title="Visualizar e editar dados bancários">
              <Button onClick={() => handleShowEmployeeBankDataModal(row)}>
                <EditOutlined color="primary" />
              </Button>
            </Tooltip>
            <Tooltip title="Cancelar Cadastro">
              <Button onClick={() => handleOpenCancelModal(row)}>
                <CancelOutlinedIcon color="error" />
              </Button>
            </Tooltip>
          </Box>
        )
      }
    },
  ];
}
